define("discourse/plugins/discourse-docs/discourse/templates/components/docs-search", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="docs-search-wrapper">
    {{input
      type="text"
      value=(readonly searchTerm)
      input=(action "onSearchTermChange" value="target.value")
      class="no-blur docs-search-bar"
      autocorrect="off"
      placeholder=(i18n "docs.search.placeholder")
      autocapitalize="off"
    }}
  
    {{#if searchTerm}}
      {{d-button action=(action "clearSearch") class="clear-search" label="docs.search.clear"}}
    {{else}}
      {{d-icon "search"}}
    {{/if}}
  </span>
  
  */
  {
    "id": "kjDlTLeP",
    "block": "[[[10,1],[14,0,\"docs-search-wrapper\"],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"type\",\"value\",\"input\",\"class\",\"autocorrect\",\"placeholder\",\"autocapitalize\"],[\"text\",[28,[37,2],[[30,0,[\"searchTerm\"]]],null],[28,[37,3],[[30,0],\"onSearchTermChange\"],[[\"value\"],[\"target.value\"]]],\"no-blur docs-search-bar\",\"off\",[28,[37,4],[\"docs.search.placeholder\"],null],\"off\"]]]],[1,\"\\n\\n\"],[41,[30,0,[\"searchTerm\"]],[[[1,\"    \"],[1,[28,[35,6],null,[[\"action\",\"class\",\"label\"],[[28,[37,3],[[30,0],\"clearSearch\"],null],\"clear-search\",\"docs.search.clear\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,7],[\"search\"],null]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[1,[28,[32,0],[\"[[\\\"The `searchTerm` property path was used in the `discourse/plugins/discourse-docs/discourse/templates/components/docs-search.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.searchTerm}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `searchTerm` property path was used in the `discourse/plugins/discourse-docs/discourse/templates/components/docs-search.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.searchTerm}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"span\",\"input\",\"readonly\",\"action\",\"i18n\",\"if\",\"d-button\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-docs/discourse/templates/components/docs-search.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});